import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import ActionCreator from "../ActionCreator";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { StaticImage } from "../Components/StaticImage";
import * as Icon from "../Components/Icon";
import * as Widget from "../Components/Widget";
import { d } from "../Utils/Lang";
import { isDesktop, isPhone } from "../Utils/WindowUtil";

class ProjectDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
    };
  }

  render() {
    let { navActions } = this.props;
    let { item } = this.props.pageContext;
    let { expand } = this.state;

    return (
      <Wrapper expand={expand}>
        <div className="introduction-section">
          <h1 className="title" style={{ fontSize: 24, marginBottom: 16 }}>
            {item.title}
          </h1>
          <strong className="paragraph-container" style={{ fontWeight: 200 }}>
            <p style={{ fontWeight: 400 }}>{item.description}</p>
          </strong>
          {/*(item.link || []).length > 0 && <hr style={{ width: "50%" }} />*/}
          <div className="exra-info" style={{ marginTop: 20 }}>
            {item.link && item.link.length >= 1 && (
              <div
                className="paragraph-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {item.link.map((l, idx) => (
                  <a
                    href={l[0]}
                    key={idx}
                    target="_blank"
                    style={{
                      color: idx === 0 ? "#2F58CD" : "#3795BD",
                      fontWeight: "bold",
                      fontSize: idx === 0 ? "1.2rem" : "1rem",
                      marginBottom: idx === 0 ? 25 : 10,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: 18,
                      ...(idx === 0
                        ? {
                            padding: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderWidth: 2,
                            borderRadius: 5,
                            borderColor: "#3B6790",
                            borderStyle: "solid",
                          }
                        : {}),
                    }}
                    alt={item.title + " " + l[1]}
                    aria-label={item.title + " " + l[1]}
                  >
                    {idx !== 0 ? (
                      <>
                        <img
                          src="/images/link-2.png"
                          width="20"
                          style={{ marginRight: 10 }}
                        />
                      </>
                    ) : (
                      ""
                    )}{" "}
                    {l[1]} {idx === 0 ? " ➜" : ""}
                  </a>
                ))}
              </div>
            )}
          </div>
          {(item.related || []).length > 0 && (
            <hr style={{ width: "50%", marginBottom: 20 }} />
          )}
          {(item.related || []).length > 0 && (
            <p>
              <b>延伸參考</b>
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {(item.related || []).map((r, idx, arr) => {
              return (
                <a
                  key={idx}
                  aria-label={r[0]}
                  style={{
                    margin: 0,
                    fontSize: 12,
                    color: "black",
                    textDecoration: "underline",
                    marginBottom: idx !== arr.length - 1 ? 15 : 0,
                    display: "flex",
                    flexDirection: "row",
                    ...(idx === 0
                      ? { color: "#EB5A3C", fontSize: 14, fontWeight: 500 }
                      : {}),
                  }}
                  target="_blank"
                  href={r[1]}
                >
                  <img
                    width="15"
                    height="15"
                    style={{ height: 15, width: 15 }}
                    src="/images/link.png"
                  />
                  <p style={{ marginLeft: 5 }}>{r[0]}</p>
                </a>
              );
            })}
          </div>

          {(item.tags || []).length > 0 && (
            <hr style={{ width: "50%", marginBottom: 20, marginTop: 15 }} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {(item.tags || []).map((t, idx) => {
              const COLORS = [
                "#E9FF97",
                "#FFD18E",
                "#9CDBA6",
                "#F7E7DC",
                "#FFD3B6",
                "#F9E2AF",
                "#ECCEAE",
                "#FDFFD2",
                "#FFFED3",
                "#E7D4B5",
                "#BBE9FF",
                "#E8C5E5",
                "#F9D689",
                "#E0A75E",
              ];
              return (
                <div
                  key={idx}
                  style={{
                    marginRight: 15,
                    marginBottom: 10,
                    padding: 10,
                    backgroundColor: COLORS[idx],
                    borderRadius: 5,
                  }}
                >
                  <h2 style={{ margin: 0, fontSize: 12 }}>{t}</h2>
                </div>
              );
            })}
          </div>

          <div className="expand-button">
            <div className="line" />

            <div className="line" />
          </div>
        </div>
        <div className="project-image-section">
          {item.images && item.images.length >= 1 && (
            <div className="project-images-container">
              {item.images.map((source, idx) => (
                <div key={idx} className="project-image">
                  {/*<ProjectImage source={source} key={idx} />*/}
                  <img src={source} key={source} style={{ width: "100%" }} />
                </div>
              ))}
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px 40px 40px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & > h2 {
      text-align: left;
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 10px;
    }

    & > p {
      font-size: 14px;
      text-align: left;
      ${ParagraphFontCss}
      margin-bottom: 20px;
    }

    .expand-button {
      margin: 15px 0px;
      display: flex;
      align-items: center;

      & .line {
        flex: 1;
        height: 1px;
        background-color: #f36a26;
      }

      & .expand {
        margin: 0px 17px;
        width: 30px;
        height: 30px;
        background-color: #f36a26;
      }
    }

    .paragraph-container {
      margin-bottom: 15px;
      & > h3 {
        font-size: 20px;
        ${HeaderFontCss}
        margin-bottom: 15px;
      }

      & > p {
        font-size: 14px;
        ${ParagraphFontCss}
        letter-spacing: 0.4px;
        white-space: pre-wrap;
        text-align: justify;
        line-height: 20px;
      }

      & a {
        /* reset default a style */
        color: #4e4e4e;
        text-decoration: none;
      }
    }

    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .project-image-section {
    flex-grow: 1;

    & .project-images-container {
      & .project-image {
        flex-basis: 100%;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .introduction-section {
      flex-basis: initial;
      flex-grow: 1;

      & > h2 {
        text-align: left;
      }

      & > p {
        text-align: left;
      }

      .paragraph-container {
        & > h3 {
          text-align: left;
        }

        & > p {
          text-align: left;
        }
      }

      .button-container {
        align-self: center;
      }
    }

    .project-image-section {
    }
  }

  @media screen and (max-width: 500px) {
    .logo-image-container {
      display: none;
    }

    .introduction-section {
      padding: 45px 15px 50px;

      .exra-info {
        overflow: hidden;
        max-height: ${props => (props.expand ? "600px" : "0px")};
        opacity: ${props => (props.expand ? "1" : "0")};
        transition: max-height linear 0.2s, opacity linear 0.2s;
      }
    }

    .project-image-section {
      & .project-items-conteainer {
        & .project-item {
          flex-basis: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 500px) {
    .introduction-section {
      .expand-button {
        display: none;
      }
    }
  }
`;

const ProjectImage = props => {
  let { source } = props;
  return (
    <ProjectImageWrapper>
      <StaticImage name={source} />
    </ProjectImageWrapper>
  );
};

const ProjectImageWrapper = styled.div`
  position: relative;
  max-height: 568px;
`;

export default withPage(connect(null, ActionCreator)(ProjectDetailPage));
